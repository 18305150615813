<template>
  <div class="vx-row" v-show="!loading">
    <div class="vx-col w-full flex justify-end mb-base">
      <vs-button
        icon="add"
        @click="$router.push({ name: 'vancynet-room-detail-add' })"
        >Create New Room</vs-button
      >
    </div>

    <div class="vx-col w-full" v-show="rooms.length > 0">
      <div id="page-rooms-detail-list">
        <div class="vx-row">
          <div
            class="vx-col w-full md:w-6/12 lg:w-4/12 mb-base"
            v-for="room in rooms"
            :key="room.id"
          >
            <div class="vx-card">
              <!-- Card Header -->
              <div class="vx-row p-6">
                <div class="vx-col w-full">
                  <h4 class="text-xl mb-2">{{ room.name }}</h4>
                  <span class="text-grey text-base">{{
                    room.roomName
                  }}</span>
                </div>
              </div>
              <!-- Card Image -->
              <div class="vx-row">
                <div class="vx-col w-full">
                  <img
                    class="block w-full h-64"
                    :src="findPropertyMainPhoto(room)"
                    alt=""
                    style="object-fit: cover !important"
                  />
                </div>
              </div>
              <!-- Card Content -->
              <div class="vx-row p-8">
                <div class="vx-col w-full">
                  Maximum Guest :
                  <span class="font-semibold"
                    >{{ room.maxOccupancy }} guest</span
                  >
                </div>
                <vs-divider></vs-divider>
                <div class="vx-col w-full">
                  Maximum Adult :
                  <span class="font-semibold"
                    >{{ room.maxAdults }} adult</span
                  >
                </div>
                <vs-divider></vs-divider>
                <div class="vx-col w-full">
                  Maximum Child :
                  <span class="font-semibold"
                    >{{ room.maxChildren }} child</span
                  >
                </div>
              </div>
              <!-- Card Footer -->
              <div class="vx-row p-6">
                <div class="vx-col w-full md:w-6/12 text-center">
                  <router-link
                    :to="{
                      name: 'vancynet-room-detail-edit',
                      params: { id: $secure.encrypt(room.id) },
                    }"
                    class="text-primary select-none cursor-pointer"
                    >Edit
                  </router-link>
                </div>
                <div class="vx-col w-full md:w-6/12 text-center">
                  <span
                    class="text-danger select-none cursor-pointer"
                    @click="showConfirmDestroyData(room)"
                    >Delete</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-col w-full" v-show="rooms.length == 0">
      <img
        class="block m-auto"
        :src="emptyDataImg"
        alt=""
        width="350"
        height="350"
      />
      <h4 class="text-3xl font-light text-center">
        You don't have any room yet
      </h4>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

import emptyDataImg from "@/assets/images/pages/search.svg";
import { mapActions } from 'vuex'

export default {
  name: "VancyNetRoomDetailIndex",

  metaInfo: {
    title: "Room Detail",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  data() {
    return {
      rooms: [],
      selectedItem: null,
      loading: true,
      emptyDataImg: emptyDataImg,
    };
  },

  computed: {
    hotelId() {
      return this.$store.state.userData().userInfo.hotelId;
    },
  },

  methods: {
    ...mapActions('room', ['getRoomList', 'findPropertyMainPhoto']),
    async getRooms() {
      this.$vs.loading({
        type: "sound",
      });
      this.rooms = await this.getRoomList({ hotelId: this.hotelId });
      this.loading = false;
      this.$vs.loading.close();
    },
    findPropertyMainPhoto(room) {
      const mainPhoto = room.images.find(image => image.isMain) ??
        { url: 'https://dummyimage.com/600x400/e6e6e6/3b3b3b&text=Main+photo+is+not+available'};
      return mainPhoto.url;
    },
    showConfirmDestroyData(room) {
      this.selectedItem = room.id;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Confirm Delete",
        text: `You are about to delete "${room.name}". Be sure all of the data referencing to this room will be deleted too`,
        accept: this.destroyData,
        acceptText: "Delete",
      });
    },

    destroyData() {
      const { hotelId } = this.$store.state.AppActiveUser.userInfo;

      axios
        .delete(`hotels/${hotelId}/rooms/${this.selectedItem}`)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Success",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });

          this.selectedItem = null;

          this.getRooms();
        });
    },
  },

  created() {
    this.getRooms();
  },
};
</script>

<style></style>
